exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ads-tsx": () => import("./../../../src/pages/ads.tsx" /* webpackChunkName: "component---src-pages-ads-tsx" */),
  "component---src-pages-affiliate-tsx": () => import("./../../../src/pages/affiliate.tsx" /* webpackChunkName: "component---src-pages-affiliate-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customer-list-tsx": () => import("./../../../src/pages/customer-list.tsx" /* webpackChunkName: "component---src-pages-customer-list-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-email-tsx": () => import("./../../../src/pages/email.tsx" /* webpackChunkName: "component---src-pages-email-tsx" */),
  "component---src-pages-forgot-password-index-tsx": () => import("./../../../src/pages/forgot_password/index.tsx" /* webpackChunkName: "component---src-pages-forgot-password-index-tsx" */),
  "component---src-pages-forgot-password-success-tsx": () => import("./../../../src/pages/forgot_password/success.tsx" /* webpackChunkName: "component---src-pages-forgot-password-success-tsx" */),
  "component---src-pages-free-account-migration-tsx": () => import("./../../../src/pages/free-account-migration.tsx" /* webpackChunkName: "component---src-pages-free-account-migration-tsx" */),
  "component---src-pages-hire-an-expert-tsx": () => import("./../../../src/pages/hire-an-expert.tsx" /* webpackChunkName: "component---src-pages-hire-an-expert-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-tsx": () => import("./../../../src/pages/reset.tsx" /* webpackChunkName: "component---src-pages-reset-tsx" */),
  "component---src-pages-social-tsx": () => import("./../../../src/pages/social.tsx" /* webpackChunkName: "component---src-pages-social-tsx" */),
  "component---src-pages-sorry-tsx": () => import("./../../../src/pages/sorry.tsx" /* webpackChunkName: "component---src-pages-sorry-tsx" */),
  "component---src-pages-teams-tsx": () => import("./../../../src/pages/teams.tsx" /* webpackChunkName: "component---src-pages-teams-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

