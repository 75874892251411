import { ResizeObserver } from '@juggle/resize-observer';
import * as Sentry from '@sentry/gatsby';
import SentryFullStory from '@sentry/fullstory';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

import './src/styles.css';

// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {
  if (!('ResizeObserver' in window)) {
    window.ResizeObserver = ResizeObserver;
  }
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer');
  }
  if (!('URLSearchParams' in window)) {
    import('url-search-params-polyfill');
  }

  const environment = process.env.GATSBY_ENVIRONMENT;
  const dsn = process.env.GATSBY_SENTRY_DSN;
  const analytics = Analytics({
    app: 'landing-ui',
    plugins: [
      googleAnalytics({
        trackingId: 'UA-117161535-2',
      }),
    ],
  });
  analytics.page();
  if (dsn && environment && environment !== 'local') {
    Sentry.init({
      dsn,
      environment,
      integrations: [
        new SentryFullStory('bizwise-inc'),
      ],
    });
  }
};
